<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <div v-if="!user?.super_admin && user?.admin" @click="startTour" class="btn btn-info mr-1">Instruction</div>

    <XdeskView
      v-if="user?.roles.data[0]?.admin === 1"
      :details="xdeskUser"
      :is-admin="false"
      secret-key="secret-6m245chapl706m5ijqpk4p612"
      :set-loader="setLoading"
      :is-loading="isLoading"
    >
      <div class="btn btn-success">
        {{ isLoading ? "Please Wait" : "Help" }}
      </div>
    </XdeskView>



    <b-navbar-nav class="nav align-items-center ml-auto">
      <template v-if="this.user?.super_admin !== 1">
        <notification-dropdown />
      </template>

      <!-- check in and out -->
      <template v-if="this.user?.remote_attendance">
        <attendance-check-in-out />
      </template>
      <!-- check in and out -->
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user?.name }}
            </p>
            <span class="user-status">
              <!-- designation -->
              <template v-if="user?.designation?.data?.name.length >= 10">
                {{ user?.designation?.data?.name.substr(0, 10) }}...
              </template>
              <template v-else>
                {{ user?.designation?.data?.name }}
              </template>
            </span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="user?.avatar === '' ? '/avatar.svg' : user?.avatar"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          v-on:click.prevent="
            () =>
              $route.name != 'user-profile'
                ? $router.push({
                    name: 'user-profile',
                    params: { id: user.id },
                  })
                : null
          "
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Profile</span>
        </b-dropdown-item>

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="MailIcon" class="mr-50" />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="CheckSquareIcon" class="mr-50" />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="MessageSquareIcon" class="mr-50" />
          <span>Chat</span>
        </b-dropdown-item> -->

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click.prevent="onLogout"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AttendanceCheckInOut from "@/views/admin/attendance/AttendanceCheckInOut.vue";
import NotificationDropdown from "./notification/NotificationDropdown";
import XdeskView from "@/views/admin/xdesk/XdeskView.vue";
import {EventBus} from "@/helpers/event-bus";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    AttendanceCheckInOut,
    NotificationDropdown,

    // Navbar Components
    DarkToggler,
    XdeskView
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {

    return {
      xdeskUser:{
        name: "projectx",
        email: "support@projectx.zone",
      },
      isLoading: false,
    };

  },
  computed: {
    ...mapGetters({
      user: "userModule/getUser",
      permissions: "userModule/getPermissions",
    }),



    avatar() {
      return require("@/assets/images/avatars/avatar.webp");
    },
  },
  methods: {
    startTour() {
      if(this.$route.fullPath === '/admin/settings/general'){
        EventBus.$emit("start-setting-tour")
      }else{
        EventBus.$emit("start-global-tour")
      }
    },
    setLoading(value) {
      this.isLoading = value;
    },
    onLogout: async function () {
      try {
        await this.$api.post("api/logout");

        await this.$store.dispatch("authModule/setIsAuthenticated", {
          isAuthenticated: false,
          token: null,
        });

        await this.$store.dispatch("authModule/clearPersistedState");

        await this.$store.dispatch("userModule/removeUser");

        await this.$store.dispatch("userModule/setPermissions", {
          permissions: null,
        });
        window.location.reload()
        this.$router.replace({ name: "login" }).then(() => window.location.reload());
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${error?.response?.data?.message}`,
            icon: "BellIcon",
            variant: "danger",
          },
        });
      }
    },
  },
  created() {
    this.xdeskUser = {
      name: this.user.name,
      email: this.user.email
    }
  },
};
</script>
