import {
  DASHBOARD_ACCESS,
  USER_DASHBOARD_ACCESS,
} from "@/helpers/permissionsConstant";
// import attendanceNavigation from "./attendanceNavigation";
// import employeeNavigation from "./employeeNavigation";
import settingNavigation from "./settingNavigation";
import leadNavigation from "./leadNavigation";
import taskNavigation from "./taskNavigation";
import rolesNavigation from "./rolesNavigation";
// import divisionNavigation from "./divisionNavigation";
// import departmentNavigation from "./departmentNavigation";
// import designationNavigation from "./designationNavigation";
// import usersNavigation from "./usersNavigation";
import assetNavigation from "./assetNavigation";
// import noticeNavigation from "./noticeNavigation";
import financeNavigation from "./financeNavigation";
import payrollNavigation from "./payrollNavigation";
import loanNavigation from "./loanNavigation";
import hrmsNavigation from "./hrmsNavigation";
import subscriptionNavigation from "./subscriptionNavigation";
import outreachNavigation from "./outreachNavigation";

export default [
  {
    title: "Dashboard",
    route: "admin-dashboard",
    icon: "PieChartIcon",
    gate: DASHBOARD_ACCESS,
    class: "dashboard-tour",
  },
  ...rolesNavigation,
  ...hrmsNavigation,
  ...leadNavigation,
  ...financeNavigation,
  ...taskNavigation,
  ...assetNavigation,
  ...outreachNavigation,
  ...loanNavigation,
  ...payrollNavigation,
  ...settingNavigation,
  ...subscriptionNavigation,
];
