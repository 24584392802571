<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
    v-on:show="resetNotificationCount"
  >
    <template #button-content>
      <feather-icon
        :badge="incomingNotificationCount"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <div class="d-flex justify-content-between align-items-center w-100">
          <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
          <span @click="markAllAsRead" class="text-primary cursor-pointer">Mark all as read</span>
        </div>
      </div>
    </li>


    <!-- Notifications -->
    <div>
      <vue-perfect-scrollbar
          ref="scrollContainer"
          @ps-scroll-y="handleScroll"
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
      >
        <div v-if="notifications.length === 0 && !isNotificationLoading" class="text-center p-2">
          <small class="text-muted">No new notifications</small>
        </div>

        <!-- Account Notification -->
        <b-link
            v-else
          v-for="notification in notifications"
          :key="notification?.id"
          v-on:click="showNotificationDetails(notification)"
        >
          <b-media>
            <template #aside>
              <b-avatar size="32" :variant="notification?.data?.variant">
                <feather-icon :icon="notification?.data?.icon" />
              </b-avatar>
            </template>
            <p class="media-heading">
              <span class="font-weight-bolder">
                {{ notification?.data?.message }}
              </span>
            </p>
            <small class="notification-text">{{
              notification?.data?.sub_title
            }}</small
            ><br />
            <small class="notification-text">
              {{
                notification?.created_at | moment("ddd, MMM Do YYYY, h:mm a")
              }}
            </small>
          </b-media>
        </b-link>
        <div v-if="isNotificationLoading">
          <div class="text-center">
            <b-spinner
                style="width: 3rem; height: 3rem"
                class="mr-1"
                variant="primary"
            />
          </div>
        </div>

      </vue-perfect-scrollbar>

    </div>


    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
          v-if="pagination.current_page < pagination.total_pages"
          variant="primary"
          block
          @click="getUnreadNotifications(pagination.current_page + 1)"
      >
        Load More
      </b-button>

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        v-on:click="onShowPage">
        View all</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
  BSpinner,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  name: "NotificationDropdown",
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
    BSpinner,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      notifications: [],
      pagination: [],
      systemNotifications: [],
      isNotificationLoading: false,
      page: 1,
      hasMoreNotifications: true,
    };
  },
  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
      authUser: "userModule/getUser",
      incomingNotificationCount: "userModule/getIncomingNotificationCount",
    }),
  },
  created() {
    this.getUnreadNotifications();
  },

  methods: {
    async markAllAsRead() {
      try {
        await this.$api.put("api/notifications/user/mark-all-as-read");

        // Remove all unread notifications from the list
        this.notifications = [];

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "CheckIcon",
            variant: "success",
            text: "All notifications marked as read!",
          },
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "XIcon",
            variant: "danger",
            text: error?.response?.data?.message || "Failed to mark notifications as read",
          },
        });
      }
    },
    handleScroll(event) {
      const scrollContainer = event.target;
      const bottomReached =
          scrollContainer.scrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight - 10;

      if (bottomReached && !this.isNotificationLoading) {
        this.getUnreadNotifications(); // Load more when bottom is reached
      }
    },
    resetNotificationCount() {
      let incomingNotificationCount = 0;
      this.$store.dispatch("userModule/setIncomingNotificationCount", {
        incomingNotificationCount,
      });

      this.getUnreadNotifications();
    },

    async getUnreadNotifications() {
      try {
        if (!this.hasMoreNotifications) return;

        this.isNotificationLoading = true;

        const response = await this.$api.get(`api/notifications/user/unread?page=${this.page}`);

        // Append new notifications to existing list
        this.notifications.push(...response?.data?.data)

        if (!response?.data?.meta?.pagination?.links?.next) {
          this.hasMoreNotifications = false;
        } else {
          this.page++; // Increment page number for next request
        }

        this.isNotificationLoading = false;
      } catch (error) {
        this.isNotificationLoading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    showNotificationDetails(notification) {
      if (notification?.data?.url) {
        const currentPageURL = window.location.href;
        const baseUrl = new URL(currentPageURL).origin;
        const notificationUrl = notification?.data?.url;
        const routeUrl = `${baseUrl}/${notificationUrl}`;
        window.open(routeUrl, "_blank");
      }

      this.markNotificationAsRead(notification);
    },
    async markNotificationAsRead(notification) {
      try {
        await this.$api.put(
          `api/notification/${notification?.id}/mark-as-read`
        );
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    onShowPage() {
      this.$router.push({
        name: "admin-notification",
      });
    },
  },
};
</script>

<style>
</style>
