import {
  PAYROLL_MANAGEMENT_ACCESS,
  SALARY_POLICY_ACCESS,
  PROJECT_COMMISSION_POLICY_ACCESS,
  MEAL_POLICY_ACCESS,
  OVER_TIME_POLICY_ACCESS,
  INSURANCE_POLICY_ACCESS,
  INCOME_TAX_POLICY_ACCESS,
  FESTIVAL_BONUS_POLICY_ACCESS,
  DELAY_FINE_POLICY_ACCESS,
  FINE_POLICY_ACCESS,
  SALARY_PAYSLIP_ACCESS,
  EXTRA_LEAVE_POLICY_ACCESS,
  PROVIDENT_FUND_POLICY_ACCESS,
  OUTREACH_SELLER_ACCESS,
  NICHE_ACCESS,
  APPROVE_FOR_NICHE_ACCESS,
  OUTREACH_INVOICE_ACCESS,
  BACK_LINK_ACCESS,
  SITE_LIST_ACCESS,
  OUTREACH_LOGS_ACCESS,
} from "@/helpers/permissionsConstant";

export default [
  {
    title: "Outreach",
    icon: "Link2Icon",
    gate: [OUTREACH_SELLER_ACCESS, NICHE_ACCESS, APPROVE_FOR_NICHE_ACCESS, BACK_LINK_ACCESS, SITE_LIST_ACCESS, OUTREACH_INVOICE_ACCESS ],
    class: "payroll-tour",
    children: [
      {
        title: "Seller",
        route: "outreach-seller",
        gate: OUTREACH_SELLER_ACCESS,
      },
      {
        title: "Niche",
        route: "outreach-niche",
        gate: NICHE_ACCESS,
      },
      {
        title: "Approve For Niche",
        route: "outreach-approvefor-niche",
        gate: APPROVE_FOR_NICHE_ACCESS,
      },
      {
        title: "Site List",
        route: "site-list",
        gate: SITE_LIST_ACCESS,
      },
      {
        title: "Backlinks",
        route: "back-link",
        gate: BACK_LINK_ACCESS,
      },
      {
        title: "Invoice",
        route: "outreach-invoice-list",
        gate: OUTREACH_INVOICE_ACCESS,
      },
      // {
      //   title: "Outreach Logs",
      //   route: "outreach-logs",
      //   gate: OUTREACH_LOGS_ACCESS,
      // },
    ],
  },
];
