var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-nav-item-dropdown', {
    staticClass: "dropdown-notification mr-25",
    attrs: {
      "menu-class": "dropdown-menu-media",
      "right": ""
    },
    on: {
      "show": _vm.resetNotificationCount
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "text-body",
          attrs: {
            "badge": _vm.incomingNotificationCount,
            "badge-classes": "bg-danger",
            "icon": "BellIcon",
            "size": "21"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('li', {
    staticClass: "dropdown-menu-header"
  }, [_c('div', {
    staticClass: "dropdown-header d-flex"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('h4', {
    staticClass: "notification-title mb-0 mr-auto"
  }, [_vm._v("Notifications")]), _c('span', {
    staticClass: "text-primary cursor-pointer",
    on: {
      "click": _vm.markAllAsRead
    }
  }, [_vm._v("Mark all as read")])])])]), _c('div', [_c('vue-perfect-scrollbar', {
    ref: "scrollContainer",
    staticClass: "scrollable-container media-list scroll-area",
    attrs: {
      "settings": _vm.perfectScrollbarSettings,
      "tagname": "li"
    },
    on: {
      "ps-scroll-y": _vm.handleScroll
    }
  }, [_vm.notifications.length === 0 && !_vm.isNotificationLoading ? _c('div', {
    staticClass: "text-center p-2"
  }, [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v("No new notifications")])]) : _vm._l(_vm.notifications, function (notification) {
    var _notification$data3, _notification$data4;
    return _c('b-link', {
      key: notification === null || notification === void 0 ? void 0 : notification.id,
      on: {
        "click": function click($event) {
          return _vm.showNotificationDetails(notification);
        }
      }
    }, [_c('b-media', {
      scopedSlots: _vm._u([{
        key: "aside",
        fn: function fn() {
          var _notification$data, _notification$data2;
          return [_c('b-avatar', {
            attrs: {
              "size": "32",
              "variant": notification === null || notification === void 0 ? void 0 : (_notification$data = notification.data) === null || _notification$data === void 0 ? void 0 : _notification$data.variant
            }
          }, [_c('feather-icon', {
            attrs: {
              "icon": notification === null || notification === void 0 ? void 0 : (_notification$data2 = notification.data) === null || _notification$data2 === void 0 ? void 0 : _notification$data2.icon
            }
          })], 1)];
        },
        proxy: true
      }], null, true)
    }, [_c('p', {
      staticClass: "media-heading"
    }, [_c('span', {
      staticClass: "font-weight-bolder"
    }, [_vm._v(" " + _vm._s(notification === null || notification === void 0 ? void 0 : (_notification$data3 = notification.data) === null || _notification$data3 === void 0 ? void 0 : _notification$data3.message) + " ")])]), _c('small', {
      staticClass: "notification-text"
    }, [_vm._v(_vm._s(notification === null || notification === void 0 ? void 0 : (_notification$data4 = notification.data) === null || _notification$data4 === void 0 ? void 0 : _notification$data4.sub_title))]), _c('br'), _c('small', {
      staticClass: "notification-text"
    }, [_vm._v(" " + _vm._s(_vm._f("moment")(notification === null || notification === void 0 ? void 0 : notification.created_at, "ddd, MMM Do YYYY, h:mm a")) + " ")])])], 1);
  }), _vm.isNotificationLoading ? _c('div', [_c('div', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    staticClass: "mr-1",
    staticStyle: {
      "width": "3rem",
      "height": "3rem"
    },
    attrs: {
      "variant": "primary"
    }
  })], 1)]) : _vm._e()], 2)], 1), _c('li', {
    staticClass: "dropdown-menu-footer"
  }, [_vm.pagination.current_page < _vm.pagination.total_pages ? _c('b-button', {
    attrs: {
      "variant": "primary",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.getUnreadNotifications(_vm.pagination.current_page + 1);
      }
    }
  }, [_vm._v(" Load More ")]) : _vm._e(), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary",
      "block": ""
    },
    on: {
      "click": _vm.onShowPage
    }
  }, [_vm._v(" View all")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }