import {
  SUBSCRIPTION_ACCESS,
  PRICING_TABLE_ACCESS,
  SUBSCRIPTION_EDIT,
  PAYMENT_HISTORY_ACCESS,
  PAYROLL_MANAGEMENT_ACCESS,
} from "@/helpers/permissionsConstant";
import store from "@/store";
const paymentMethod = store.getters["paymentMethodModule/getPaymentMethod"];

const stripeRoutes = [
  {
    title: "Subscription",
    icon: "DollarSignIcon",
    gate: SUBSCRIPTION_ACCESS,
    children: [
      {
        title: "Pricing Table",
        route: "pricing-table",
        gate: PRICING_TABLE_ACCESS,
      },
      {
        title: "Payment History",
        route: "payment-histories",
        gate: PAYMENT_HISTORY_ACCESS,
      },
    ],
  },
]

const sslCommerzRoutes = [
  {
    title: "Subscription",
    icon: "DollarSignIcon",
    gate: SUBSCRIPTION_ACCESS,
    children: [
      {
        title: "Pricing Table",
        route: "pricing-table",
        gate: PRICING_TABLE_ACCESS,
      },
      {
        title: "Payment History",
        route: "payment-histories",
        gate: PAYMENT_HISTORY_ACCESS,
      },
    ],
  },
]

const manualPaymentRoutes = [
  {
    title: "Subscription",
    icon: "DollarSignIcon",
    gate: SUBSCRIPTION_ACCESS,
    children: [
      {
        title: "Pricing Table",
        route: "pricing-table",
        gate: PRICING_TABLE_ACCESS,
      },
      {
        title: "Payment History",
        route: "payment-histories",
        gate: PAYMENT_HISTORY_ACCESS,
      },
    ],
  },
]

let filteredRoutes = [];

if (paymentMethod === 1) {
  filteredRoutes = stripeRoutes;
} else if (paymentMethod === 2) {
  filteredRoutes = sslCommerzRoutes;
} else if (paymentMethod === 3) {
  filteredRoutes = manualPaymentRoutes;
}

export default [
  ...filteredRoutes,
];
